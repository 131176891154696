import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"

export default function JobApplicationOptions() {
  const { saveUser } = useContext(UserContext)
  const [casualRole, setCasualRole] = useState(false)
  const [cleaningRole, setCleaningRole] = useState(false)

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold ml-4 mt-4">
          Please select to put a tick on your Job option
        </h1>

        <div className="p-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={casualRole}
              onChange={() => {
                setCasualRole(!casualRole)
                saveUser({
                  casual_labour_role: !casualRole,
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              for="defaultCheck1"
            >
              Casual Labour Role
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={cleaningRole}
              onChange={() => {
                setCleaningRole(!cleaningRole)
                saveUser({
                  cleaning_specialist_role: !cleaningRole,
                })
              }}
              style={{
                transform: "scale(5)",
                marginLeft: "1em",
                marginTop: "2em",
              }}
            />
            <label
              className="form-check-label display-3"
              style={{ marginLeft: "1em" }}
              for="defaultCheck1"
            >
              Cleaning Specialist Role
            </label>
          </div>
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            size="medium"
            text="Next"
            disabled={!casualRole && !cleaningRole}
            onClick={() => navigate("/registration-form-start")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/job-application-start")}
          />
        </div>
      </div>
    </Background>
  )
}
