import React from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"

export default function PayoutOptionGIRO() {
  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">GIRO Payment</h1>

        <p className="lead font-weight-bold mt-4 p-4">
          I understand that I shall be receiving my contractor's fee through the
          bank account provided in my Bank Statement of Bank Book.
          <br />
          <br />
          I further acknowledge that I shall be collecting the fee in cash
          should there be any delay in the confirmation of the fee before
          respective due dates.
          <br />
          How it works:
          <br />
          <ul>
            1. Every THURSDAY the Company will send you a WhatsApp message and
            let you know how much your contractor fee is. You MUST ACKNOWLEDGE
            if the amount is correct before FRIDAY, 7AM or else your contractor
            fee will turn into self-collection.
          </ul>
          <ul>
            2. GIRO always has a Transaction Fee (if you fail to acknowledge by
            7AM, FRIDAY it will still deduct the Transaction Fee and you need to
            collect your contractor fee in our office)
          </ul>
          <ul>
            3. Processing of contractor fee takes 1 up to 3 working days before
            it will appear on your Bank Account (DEPENDS ON YOUR BANK)
          </ul>
          I further understand that the first time to switch the above payment
          method is free, thereafter, an administrative fee of S$50 each time of
          switch is chargeable by the Company.
        </p>

        <div className="p-4 d-flex">
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/select-payout-methods")}
          />
          <Button
            text="I Agree"
            size="medium"
            onClick={() => navigate("/credentials")}
          />
        </div>
      </div>
    </Background>
  )
}
