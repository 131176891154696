import React, { useContext, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import SignaturePad from "react-signature-pad-wrapper"

export default function Signature() {
  const { user, saveUser } = useContext(UserContext)
  let signatureRef = useRef() //reference hook

  const saveSignature = () => {
    const dataURL = signatureRef.toDataURL()
    console.log(dataURL)
    saveUser({ signature: dataURL })
  }

  const clearSignature = () => {
    signatureRef.clear && signatureRef.clear()
    saveUser({ signature: null })
  }

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold ml-4 mt-4">Signature</h1>
        <h3 className="mb-4 text-center">
            Signing here indicates your knowledge of and consent to the terms.
          <br />
          You will not be able to continue unless a signature is provided.
        </h3>

        {!user.signature ? (
          <div>
            <div className="p-4" style={{ backgroundColor: "white" }}>
              <SignaturePad
                ref={ref => (signatureRef = ref)}
                width={700}
                height={400}
              />
            </div>

            <p className="lead text-center mt-4">
              Please sign on the white canvas and tap "Save" when you are done.
            </p>
          </div>
        ) : (
          <div>
            <img src={user.signature} className="p-4" />
          </div>
        )}

        <div className="flex">
          <Button
            text="Save"
            size="medium"
            onClick={() => {
              saveSignature()
            }}
          />
          <Button
            size="medium"
            text="Clear"
            onClick={() => {
              clearSignature()
            }}
          />
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={!user.signature}
            onClick={() => navigate("/select-payout-methods")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-3d")}
          />
        </div>
      </div>
    </Background>
  )
}
