import React, { createContext, useEffect, useState } from "react"
import bgMusic from "../../static/sounds/bg_music.mp3"

const defaultContext = {
  nric: "", //unique id
  name: "",
  email: "",
  password: "",
  mobile_number: "",
  casual_labour_role: false,
  cleaning_specialist_role: false,
  nric_front: null, //base64 image
  nric_back: null, //base64 image
  face: null, //base64 image
  birth_date: "",
  gender: "",
  nationality: "",
  singapore_pr: "",
  civil_status: "",
  race: "",
  religion: "",
  singapore_pr_date: "",
  no_of_children: 0,
  address: "",
  residential_number: "",
  residential_phone_no: "",
  nok_name: "",
  nok_relationship: "",
  nok_birth_date: "",
  nok_residential_address: "",
  nok_residential_number: "",
  nok_mobile_number: "",
  highest_edu_school: "",
  highest_edu_years: "",
  highest_edu_qualification: "",
  current_edu_school: "",
  current_edu_years: "",
  current_edu_qualification: "",
  currentPage: 1,
  appendix1_option: "No",
  appendix1_details: "",
  appendix2_option: "No",
  appendix2_details: "",
  appendix3_option: "No",
  appendix3_details: "",
  appendix4_option: "No",
  appendix4_details: "",
  substitute_name: "",
  substitute_contractor_code: "",
  substitute_contact_number: "",
  salary_payment_method: "Cash",
  feedback: "",
  audio: {},
  signature: null,
}

export const UserContext = createContext(defaultContext)
const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    nric: "", //unique id
    name: "",
    email: "",
    password: "",
    mobile_number: "",
    casual_labour_role: false,
    cleaning_specialist_role: false,
    nric_front: null, //base64 image
    nric_back: null, //base64 image
    face: null, //base64 image
    birth_date: "",
    gender: "",
    nationality: "",
    singapore_pr: "",
    civil_status: "",
    race: "", //todo: add column database
    religion: "", //todo: add column database
    singapore_pr_date: "",
    no_of_children: 0,
    address: "", //residential address
    residential_number: "",
    residential_phone_no: "", //TODO: add column to database
    nok_name: "",
    nok_relationship: "",
    nok_birth_date: "",
    nok_residential_address: "",
    nok_residential_number: "",
    nok_mobile_number: "",
    highest_edu_school: "",
    highest_edu_years: "",
    highest_edu_qualification: "",
    current_edu_school: "",
    current_edu_years: "",
    current_edu_qualification: "",
    currentPage: 1,
    appendix1_option: "No",
    appendix1_details: "",
    appendix2_option: "No",
    appendix2_details: "",
    appendix3_option: "No",
    appendix3_details: "",
    appendix4_option: "No",
    appendix4_details: "",
    salary_payment_method: "Cash",
    feedback: "",
    audio: {},
    signature: null,
  })

  const saveUser = data => {
    setUser({ ...user, ...data })
  }

  return (
    <UserContext.Provider value={{ user, saveUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
