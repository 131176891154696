import React, { useContext, useState, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

export default function ContractorAgreement3A() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="dt-flex flex-column m-4">
        <h1 className="display-5 font-weight-bold">Appendix 3</h1>

        <table className="table">
          <tbody>
            <tr>
              <td style={{ width: "50px" }}>
                <h3>1</h3>
              </td>
              <td style={{ width: "800px" }}>
                <h3>
                  Have you ever been charged
                  <br />
                  with any offence/convicted of a<br />
                  criminal charge/detained by the <br />
                  authorities under the provisions of
                  <br />
                  any law in country?
                  <br />
                  <br />
                  If your answer is yes,
                  <br />
                  please give details in the space provided.
                </h3>
              </td>
              <td>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appendix1_option"
                      style={{
                        transform: "scale(3)",
                        marginTop: "1em",
                      }}
                      value="Yes"
                      defaultChecked={user.appendix1_option === "Yes"}
                      onChange={e => saveField(e)}
                    />
                    <h3
                      className="form-check-label"
                      style={{ marginLeft: "1em" }}
                    >
                      Yes
                    </h3>
                  </div>
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="appendix1_option"
                      style={{
                        transform: "scale(3)",
                        marginTop: "1em",
                      }}
                      value="No"
                      defaultChecked={user.appendix1_option === "No"}
                      onChange={e => {
                        saveUser({ appendix1_details: "" })
                        saveField(e)
                      }}
                    />
                    <h3
                      className="form-check-label"
                      style={{ marginLeft: "1em" }}
                    >
                      No
                    </h3>
                  </div>
                </div>

                <div className="form-group">
                  <h5 className="mt-4 form-check-label font-weight-bold">
                    If yes, please provide details below:
                  </h5>
                  <br />
                  <textarea
                    autoComplete="off"
                    name="appendix1_details"
                    onFocus={() => setInputName("appendix1_details")}
                    style={{
                      transform: "scale(2)",
                      marginLeft: "5em",
                      marginTop: "2em",
                      width: "40%",
                    }}
                    disabled={
                      user.appendix1_option === "No" || !user.appendix1_option
                    }
                    value={user.appendix1_details}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={
              !user.appendix1_option === "No" ||
              (user.appendix1_option === "Yes" && !user.appendix1_details)
            }
            onClick={() => navigate("/contractor-agreement-3b")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-2a")}
          />
        </div>
        <div
          className="fixed-bottom w-50 p-4"
          style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
        >
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            inputName={inputName}
            layoutName={layoutName}
            onChangeAll={onChangeAll}
            onKeyPress={onKeyPress}
          />
        </div>
      </div>
    </Background>
  )
}
