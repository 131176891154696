import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"

export default function RegistrationFormStart() {
  const { user } = useContext(UserContext)
  console.log(user)

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold ml-4">Contractor Agreement</h1>

        <p className="p-4 lead">
          I understand that this Agreement is served to confirm my understanding
          and obligation as a registered contractor for the usage of Primarius
          App or Primarius Contract Work Service ("PCWS").
          <br />
          <br />
          I hereby acknowledge and agree to the terms and conditions as per set
          out below:
          <br />
          <br />
          <strong>1)</strong> Primarius App is an online application which will
          allow me to apply for any listed contract job. PCWS is a Contractor
          Work Allocation Service that allows me to apply and confirm any
          contract job.
          <br />
          <br />
          <strong>2)</strong> I agree to carry out the duties and
          responsibilities of any such contract job (hereinafter the{" "}
          <strong>“Services”</strong>) which I have booked, as directed from
          time to time by ALPHAVIBE and the customer of the contract job
          (hereinafter the <strong>“Customer”</strong>) or any of their
          authorised representatives in accordance with ALPHAVIBE’s and the
          Customers standards and any relevant codes of practice as set out. I
          acknowledge that ALPHAVIBE and/or the Customer may vary the nature and
          scope of the booked job and I agree to any such variation at all
          times.
          <br />
          <br />
          <strong>3)</strong> I confirm that I am registered with ALPHAVIBE as
          an approved contractor, and am eligible to book a contract job using
          Primarius App or PCWS. Subject to Clause 14, I agree to the fee as
          quoted on Primarius App or PCWS for the contract job booked and that
          such fee will only be payable to me upon completion of the relevant
          booked contract job.
          <br />
          <br />
          <strong>4)</strong> I am acting as an independent contractor and not
          as an employee of ALPHAVIBE or the Customer asserting any rights under
          the Employment Act. As such, ALPHAVIBE and the Customer are not liable
          to provide me with statutory benefits such as Leave and Medical
          Reimbursements, and they are also not liable for my CPF contributions.
          I acknowledge that this is exclusively a contract for service.
        </p>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-a")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/registration-form-c")}
          />
        </div>
      </div>
    </Background>
  )
}
