import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"

export default function ContractorAgreementE() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold ml-4">Contractor Agreement</h1>

        <p className="p-4 lead">
          <strong>14)</strong> I understand that the Service Performance Time
          for the contract job as per indicated in the Primarius App or PCWS may
          or may not include my personal break time, which is not payable. I
          acknowledge that the actual fee that I will receive for this contract
          job will reflect the actual number of hours worked as reported by the
          Customer.
          <br />
          <br />
          <strong>15)</strong> I shall be collecting my fees within 1 month from
          the fee payout date or else ALPHAVIBE has the right to impose an
          administrative fee of SGD 100, or the sum of the contractor fees,
          whichever of lower value.
          <br />
          <br />
          <strong>16)</strong> I, as an approved contractor registered with
          ALPHAVIBE, shall be committed to ALPHAVIBE for using Primarius App or
          PCWS as my source of booking on the contract job during my time as an
          approved contractor. During my time as a registered contractor or
          within a period of 12 months after I cease to be the registered
          contractor with ALPHAVIBE, I shall not be engaged in similar contract
          work directly for the same Customer through direct hire by the
          Customer or through any other agencies. Or otherwise, ALPHAVIBE shall
          have the rights to claim an amount of SGD 1000 against myself as
          compensation for ALPHAVIBE’s loss if I shall break this commitment.
          <br />
          <br />
        </p>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-f")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-d")}
          />
        </div>
      </div>
    </Background>
  )
}
