import React, { useContext, useState, useRef } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

export default function RegistrationFormC() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-5 font-weight-bold mt-4">
          C. Educational, Language and Other Skillsets
        </h1>

        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>
                <h3>Name of Institution / Country</h3>
              </th>
              <th>
                <h3>
                  Years
                  <br />
                  Attended
                </h3>
              </th>
              <th>
                <h3>Qualification/Course</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h3>
                  Current Education
                  <br />
                  Level
                </h3>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="current_edu_school"
                    onFocus={() => setInputName("current_edu_school")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "3.5em",
                      marginTop: "1em",
                      width: "50%",
                    }}
                    value={user.current_edu_school}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="current_edu_years"
                    onFocus={() => setInputName("current_edu_years")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "1.5em",
                      marginTop: "1em",
                      width: "30%",
                    }}
                    value={user.current_edu_years}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="current_edu_qualification"
                    onFocus={() => setInputName("current_edu_qualification")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "3em",
                      marginTop: "1em",
                      width: "45%",
                    }}
                    value={user.current_edu_qualification}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <h3>
                  Highest Education
                  <br />
                  Level
                </h3>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="highest_edu_school"
                    onFocus={() => setInputName("highest_edu_school")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "3.5em",
                      marginTop: "1em",
                      width: "50%",
                    }}
                    value={user.highest_edu_school}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="highest_edu_years"
                    onFocus={() => setInputName("highest_edu_years")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "1.5em",
                      marginTop: "1em",
                      width: "30%",
                    }}
                    value={user.highest_edu_years}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
              <td>
                <div className="form-group">
                  <input
                    type="text"
                    name="highest_edu_qualification"
                    onFocus={() => setInputName("highest_edu_qualification")}
                    autoComplete="off"
                    style={{
                      transform: "scale(1.5)",
                      marginLeft: "3em",
                      marginTop: "1em",
                      width: "45%",
                    }}
                    value={user.highest_edu_qualification}
                    onChange={e => saveField(e)}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            disabled={
              !user.highest_edu_school ||
              !user.highest_edu_years ||
              !user.highest_edu_qualification ||
              !user.current_edu_school ||
              !user.current_edu_years ||
              !user.current_edu_qualification
            }
            onClick={() => navigate("/contractor-agreement-start")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/registration-form-b")}
          />
        </div>
        <div
          className="fixed-bottom w-50 p-4"
          style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
        >
          <Keyboard
            keyboardRef={r => (keyboard.current = r)}
            inputName={inputName}
            layoutName={layoutName}
            onChangeAll={onChangeAll}
            onKeyPress={onKeyPress}
          />
        </div>
      </div>
    </Background>
  )
}
