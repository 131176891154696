import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import { ReactVideo } from "reactjs-media"
import GroomingVideo from "../../static/videos/Grooming_video.mp4"
import GroomingPoster from "../../static/images/poster.png"

export default function ContractorAgreement2A() {
  const { user } = useContext(UserContext)

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-5 font-weight-bold">Appendix 2</h1>
        <p className="font-weight-bold">
          I will now show you the video on grooming standard required for work.
        </p>

        <ReactVideo
          src={GroomingVideo}
          poster={GroomingPoster}
          primaryColor="blue"
          onPlay={() => {
            user.audio && user.audio.pause && user.audio.pause()
          }}
        />

        <div className="text-center">
          <p className="font-weight-bold p-4">
            By agreeing and accepting, you accept and agree to the rules and
            regulations that have been briefed to you.
            <br /> Please click "I Agree" button to confirm your acceptance and
            agreement.
          </p>
        </div>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="I Agree"
            size="medium"
            onClick={() => {
              user.audio && user.audio.play && user.audio.play()
              navigate("/contractor-agreement-3a")
            }}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-1a")}
          />
        </div>
      </div>
    </Background>
  )
}
