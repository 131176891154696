import React from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"

export default function JobApplicationStart() {
  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">
          Job Application
        </h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please be informed that the whole
          <br />
          job application process will be recorded
          <br />
          for file record purpose.
        </h1>

        <h1 className="display-5 font-weight-bold mt-4 p-4">
          Please click I Agree to Proceed
        </h1>

        <div className="p-4 d-flex">
          <Button size="medium" text="Back" onClick={() => navigate("/menu")} />
          <Button
            text="I Agree"
            size="medium"
            onClick={() => navigate("/job-application-options")}
          />
        </div>
      </div>
    </Background>
  )
}
