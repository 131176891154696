import React, { useContext, useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import DatePicker from "react-datepicker"
import moment from "moment"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

export default function RegistrationFormA2() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const [birthDate, setBirthDate] = useState(new Date("2000-01-01"))
  const [singaporePrDate, setSingaporePrDate] = useState(new Date())

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
    console.log("Inputs changed", inputs, user)
  }

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    if (inputName === "mobile_number") {
      setLayoutName("numeric")
    } else {
      setLayoutName("default")
    }
  }, [inputName])

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-5 text-center font-weight-bold mt-4">
          A. Personal Particulars (pt 2)
        </h1>

        <div className="row">
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Residential Address:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="address"
                  onFocus={() => setInputName("address")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "5em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.address}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Residential No:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="residential_number"
                  onFocus={() => setInputName("residential_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "5em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.residential_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-3 mt-4">
                Mobile No:
              </h3>
              <div className="col-9">
                <input
                  type="text"
                  name="mobile_number"
                  onFocus={() => setInputName("mobile_number")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "5em",
                    marginTop: "2em",
                    width: "50%",
                  }}
                  value={user.mobile_number}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
          <div className="d-flex flex-row-reverse fixed-bottom p-4">
            <Button
              text="Next"
              size="medium"
              disabled={
                !user.address || !user.residential_number || !user.mobile_number
              }
              onClick={() => navigate("/registration-form-b")}
            />
            <Button
              size="medium"
              text="Back"
              onClick={() => navigate("/registration-form-a")}
            />
          </div>
          <div
            className="fixed-bottom w-50 p-4"
            style={{ transform: "scale(1.5)", marginLeft: 180, height: 320 }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} q w e r t y u i o p [ ] \\",
                  "{lock} a s d f g h j k l ; ' {enter}",
                  "{shift} z x c v b n m , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        </div>
      </div>
    </Background>
  )
}
