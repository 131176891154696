import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"

export default function ContractorAgreementD() {
  const { user, saveUser } = useContext(UserContext)

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="font-weight-bold ml-4">Contractor Agreement</h1>

        <p className="p-4 lead">
          <strong>11)</strong> I acknowledge that the words “confidential
          information” include but are not limited to:
          <br />
          &nbsp;&nbsp;<strong>•</strong> Personal data identifying or relating
          to any of ALPHAVIBE, the Customer, and the Customer’s customers
          (including but not limited to names, addresses and other personal
          information), suppliers/contractors or employees;
          <br />
          &nbsp;&nbsp;<strong>•</strong> Training materials, and other
          confidential material provided to me during the course of ALPHAVIBE
          and the Customer’s onboarding process;
          <br />
          &nbsp;&nbsp;<strong>•</strong> Details of relationships or
          arrangements with ALPHAVIBE and the Customer’s other suppliers and
          business partners;
          <br />
          &nbsp;&nbsp;<strong>•</strong> Details of ALPHAVIBE and the Customer’s
          business methods, finances, pricing strategy, marketing or development
          plans or strategies; and
          <br />
          &nbsp;&nbsp;<strong>•</strong> Any other information I know to have
          been divulged to myself by ALPHAVIBE and the Customer, or by a third
          party in the course of performing the Services, in confidence.
          <br />
          <br />
          <strong>12)</strong> I also acknowledge that ALPHAVIBE and the
          Customer may need to process my personal data for a variety of legal
          and administrative purposes. This data may include information
          relating to my contractual agreement with ALPHAVIBE and my performance
          of the Services and for the purposes of record keeping and invoicing.
          <br />
          <br />
          <strong>13)</strong> I will be receiving the fee of the contract job
          in the following manner:
          <br />
          &nbsp;&nbsp;<strong>i.</strong> Subject to Clause 13(ii), the fee
          payout date will generally be on a Friday, One week after the work
          week.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;For example: If the job is performed between
          23rd till 29th of July 2018, the fee shall be paid on 3rd of August
          2018.
          <br />
          &nbsp;&nbsp;<strong>ii.</strong> ALPHAVIBE can, at any time and from
          time to time, exercise its sole discretion to withhold the contractor
          fee payment to a later date, no later than 1 month from the contract
          job.
          <br />
          <br />
        </p>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="Next"
            size="medium"
            onClick={() => navigate("/contractor-agreement-e")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/contractor-agreement-c")}
          />
        </div>
      </div>
    </Background>
  )
}
