import React, { useContext, useState, useRef, useEffect } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import DatePicker from "react-datepicker"
import moment from "moment"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"

export default function RegistrationFormA() {
  const { user, saveUser } = useContext(UserContext)
  const [inputs, setInputs] = useState({})
  const [layoutName, setLayoutName] = useState("default")
  const [inputName, setInputName] = useState("default")
  const [birthDate, setBirthDate] = useState(new Date("2000-01-01"))
  const [singaporePrDate, setSingaporePrDate] = useState(new Date())

  const onChangeAll = inputs => {
    /**
     * Here we spread the inputs into a new object
     * If we modify the same object, react will not trigger a re-render
     */
    setInputs({ ...inputs })
    saveUser({
      ...user,
      ...inputs,
    })
  }

  useEffect(() => {
    if (inputName === "no_of_children") {
      setLayoutName("numeric")
    } else {
      setLayoutName("default")
    }
  }, [inputName])

  const handleShift = () => {
    const newLayoutName = layoutName === "default" ? "shift" : "default"
    setLayoutName(newLayoutName)
  }

  const onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift()
  }

  const saveField = e => {
    saveUser({
      [e.target.name]: e.target.value,
    })
  }

  const keyboard = useRef()

  return (
    <Background container="true">
      <div className="d-flex flex-column">
        <h1 className="display-5 text-center font-weight-bold mt-4">
          A. Personal Particulars (pt 1)
        </h1>

        <div className="row">
          <div className="col-6">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-4">
                Name <br />
                <span className="small">
                  (as in
                  <br />
                  ID/Passport)
                </span>
              </h3>
              <div className="col-8">
                <input
                  type="text"
                  name="name"
                  onFocus={() => setInputName("name")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginLeft: "2.5em",
                    marginTop: "2em",
                    width: "55%",
                  }}
                  value={user.name}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>

            <div className="form-group row mt-4">
              <div className="col-4">
                <h3
                  htmlFor="gender"
                  className="form-check-label font-weight-bold"
                >
                  Date of Birth
                </h3>
              </div>
              <div
                style={{
                  transform: "scale(1.5)",
                  zIndex: 2000,
                  marginLeft: 30,
                }}
              >
                <DatePicker
                  showYearDropdown
                  selected={birthDate}
                  onChange={date => {
                    setBirthDate(date)
                    saveUser({
                      birth_date: moment(date).format("YYYY-MM-DD").toString(),
                    })
                  }}
                  style={{ transform: "scale(2)" }}
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-4">
                <h3 className="form-check-label font-weight-bold">Gender</h3>
              </div>
              <div className="form-check col-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Male"
                  defaultChecked={user.gender === "Male"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Male
                </h3>
              </div>
              <div className="form-check col-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="gender"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Female"
                  defaultChecked={user.gender === "Female"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Female
                </h3>
              </div>
            </div>

            <div className="form-group row mt-4">
              <div className="col-4">
                <h3 className="form-check-label font-weight-bold">
                  Singapore PR
                </h3>
              </div>
              <div className="form-check col-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="singapore_pr"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Yes"
                  defaultChecked={user.singapore_pr === "Yes"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Yes
                </h3>
              </div>
              <div className="form-check col-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="singapore_pr"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="No"
                  defaultChecked={user.singapore_pr === "No"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  No
                </h3>
              </div>
            </div>

            <div className="form-group row" style={{ marginTop: "2em" }}>
              <div className="col-4">
                <h3 className="form-check-label font-weight-bold">
                  Marital Status
                </h3>
              </div>
              <div className="form-check col-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="civil_status"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Single"
                  defaultChecked={user.civil_status === "Single"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Single
                </h3>
              </div>
              <div className="form-check col-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="civil_status"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Married"
                  defaultChecked={user.civil_status === "Married"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Married
                </h3>
              </div>
              <div className="form-check col-3 offset-4 mt-4">
                <input
                  className="form-check-input"
                  type="radio"
                  name="civil_status"
                  style={{
                    transform: "scale(3)",
                    marginTop: "1em",
                  }}
                  value="Divorced"
                  defaultChecked={user.civil_status === "Divorced"}
                  onChange={e => saveField(e)}
                />
                <h3 className="form-check-label" style={{ marginLeft: "1em" }}>
                  Divorced
                </h3>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-4 mt-4">
                Race
              </h3>
              <div className="col-8">
                <input
                  type="text"
                  name="race"
                  onFocus={() => setInputName("race")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.race}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-4 mt-4">
                Religion
              </h3>
              <div className="col-8">
                <input
                  type="text"
                  name="religion"
                  onFocus={() => setInputName("religion")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.religion}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>

            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-4 mt-4">
                IC No
              </h3>
              <div className="col-8">
                <input
                  type="text"
                  name="nric"
                  onFocus={() => setInputName("nric")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1.5em",
                  }}
                  value={user.nric}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>

            <div className="form-group row mt-4">
              <div className="col-4">
                <h3
                  htmlFor="gender"
                  className="form-check-label font-weight-bold"
                >
                  Date
                  <br />
                  attained PR
                </h3>
              </div>
              <div
                style={{
                  transform: "scale(1.5)",
                  zIndex: 1100,
                  marginTop: 20,
                  marginLeft: 15,
                }}
              >
                <DatePicker
                  showYearDropdown
                  selected={singaporePrDate}
                  onChange={date => {
                    setSingaporePrDate(date)
                    saveUser({
                      singapore_pr_date: moment(date)
                        .format("YYYY-MM-DD")
                        .toString(),
                    })
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <h3 className="form-check-label font-weight-bold col-4">
                Number of Children
              </h3>
              <div className="col-8">
                <input
                  type="number"
                  name="no_of_children"
                  onFocus={() => setInputName("no_of_children")}
                  autoComplete="off"
                  style={{
                    transform: "scale(1.5)",
                    marginTop: "1em",
                  }}
                  value={user.no_of_children}
                  onChange={e => saveField(e)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="d-flex flex-row-reverse fixed-bottom p-4">
            <Button
              text="Next"
              size="medium"
              disabled={
                !user.name ||
                !user.gender ||
                !user.singapore_pr ||
                !user.race ||
                !user.religion ||
                !user.nric ||
                !user.civil_status
              }
              onClick={() => navigate("/registration-form-a2")}
            />
            <Button
              size="medium"
              text="Back"
              onClick={() => navigate("/registration-form-start")}
            />
          </div>
          <div
            className="fixed-bottom w-50 p-4"
            style={{
              transform: "scale(1.5)",
              marginLeft: 180,
              height: 320,
            }}
          >
            <Keyboard
              keyboardRef={r => (keyboard.current = r)}
              inputName={inputName}
              layoutName={layoutName}
              onChangeAll={onChangeAll}
              onKeyPress={onKeyPress}
              layout={{
                default: [
                  "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                  "{tab} q w e r t y u i o p [ ] \\",
                  "{lock} a s d f g h j k l ; ' {enter}",
                  "{shift} z x c v b n m , . / {shift}",
                  ".com @ {space}",
                ],
                shift: [
                  "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                  "{tab} Q W E R T Y U I O P { } |",
                  '{lock} A S D F G H J K L : " {enter}',
                  "{shift} Z X C V B N M < > ? {shift}",
                  ".com @ {space}",
                ],
                numeric: ["1 2 3 4 5 6 7 8 9 0 {bksp}"],
              }}
            />
          </div>
        </div>
      </div>
    </Background>
  )
}
