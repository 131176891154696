import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"

export default function RegistrationFormStart() {
  const { user } = useContext(UserContext)
  console.log(user)

  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">
          Registration Form
        </h1>

        <h2 className="mt-4 p-4">
          <strong>Instructions:</strong>
          <br />
          1. This form has been designed to provide the Company the fundamental
          information required to determine the contractor's eligibility to
          participate and use{" "}
          <strong>Primarius App or Primarius Contract Work Service.</strong>
          <br />
          <br />
          2. All pertinent sections of this application form must be completed.
          <br />
          <br />
          3. The Company reserves the right to reject or terminate the
          contractor's eligibility to use{" "}
          <strong>Primarius App or Primarius Contract Work Service</strong> if
          any of the information in this registration form is found to be
          fictitious.
        </h2>

        <div className="d-flex flex-row-reverse fixed-bottom p-4">
          <Button
            text="I Agree to Proceed"
            size="medium"
            onClick={() => navigate("/registration-form-a")}
          />
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/job-application-options")}
          />
        </div>
      </div>
    </Background>
  )
}
