import React, { useEffect, useContext } from "react"
import { UserContext } from "../context/userContext"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"

export default function Menu() {
  const { user } = useContext(UserContext)

  useEffect(() => {
    console.log(user)
  }, [])
  return (
    <Background>
      <div className="d-flex flex-column">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">Welcome</h1>

        <h1 className="display-5 font-weight-bold ml-4 mt-4">
          Please select your option
        </h1>

        <div className="p-4 d-flex">
          <Button
            text="Job Application"
            onClick={() => {
              navigate("/job-application-start")
            }}
          />
          {/* <Button text="Salary Collection" /> */}
        </div>

        {/* <div className="p-4 d-flex">
          <Button text="Ask Questions" />
          <Button text="Talk to Us" />
        </div> */}
      </div>
    </Background>
  )
}
