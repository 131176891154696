import React, { useEffect, useContext } from "react"
import { navigate } from "gatsby"
import Background from "../components/Background"
import { UserContext } from "../context/userContext"
import PrimariusLogo from "../../static/images/primarius-trans-logo.png"
import bgMusic from "../../static/sounds/bg_music.mp3"

export default function Home() {
  const { user, saveUser } = useContext(UserContext)

  useEffect(() => {
    if (!user.audio || !user.audio.play) {
      let music = new Audio(bgMusic)
      music.loop = true
      saveUser({ audio: music })
    }
  }, [])

  return (
    <Background
      onClick={() => {
        navigate("/menu")
        user.audio && user.audio.play && user.audio.play()
      }}
    >
      <div className="d-flex justify-content-center col-12 my-auto">
        <img className="mt-4" src={PrimariusLogo} alt="Primarius Logo" />
      </div>
      <div className="d-flex align-bottom justify-content-center p-4 col-12 my-auto">
        <h1 className="mt-4 p-4 display-3 font-weight-bold">
          Touch Screen to Proceed
        </h1>
      </div>
    </Background>
  )
}
