import React from "react"
import { navigate } from "gatsby"
import Button from "../components/Button"
import Background from "../components/Background"

export default function PayoutOptionCash() {
  return (
    <Background>
      <div className="d-flex flex-column align-items-center">
        <h1 className="display-3 font-weight-bold ml-4 mt-4">
          Self-collection in Cash
        </h1>

        <h4 className="font-weight-bold mt-4 p-4">
          I understand that I shall collect my contractor's fee at the Company's
          office on the agreed due dates respectively.
        </h4>

        <h4 className="font-weight-bold text-center mt-4 p-4">
          I further understand that the first time to switch the above payment
          method is free,
          <br />
          thereafter, an administrative fee of S$50 each time of switch is
          chargeable by the Company.
        </h4>

        <h4 className="font-weight-bold text-center mt-4 p-4">
          By agreeing and accepting, you accept and agree to the rules and
          regulations that have been briefed to you.
          <br />
          Click "Agree" button to confirm you acceptance and agreement.
          <br />
          If you are not agreeing or to view the payout options again, please
          click "Back" button.
        </h4>

        <div className="p-4 d-flex">
          <Button
            size="medium"
            text="Back"
            onClick={() => navigate("/select-payout-methods")}
          />
          <Button
            text="I Agree"
            size="medium"
            onClick={() => navigate("/credentials")}
          />
        </div>
      </div>
    </Background>
  )
}
